/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@fontsource/biryani'
import '@fontsource/biryani/800.css'

export const onClientEntry = () => {}
