exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-control-systems-casambi-index-js": () => import("./../../../src/pages/control-systems/casambi/index.js" /* webpackChunkName: "component---src-pages-control-systems-casambi-index-js" */),
  "component---src-pages-control-systems-helvar-index-js": () => import("./../../../src/pages/control-systems/helvar/index.js" /* webpackChunkName: "component---src-pages-control-systems-helvar-index-js" */),
  "component---src-pages-control-systems-inventronics-index-js": () => import("./../../../src/pages/control-systems/inventronics/index.js" /* webpackChunkName: "component---src-pages-control-systems-inventronics-index-js" */),
  "component---src-pages-control-systems-knx-index-js": () => import("./../../../src/pages/control-systems/knx/index.js" /* webpackChunkName: "component---src-pages-control-systems-knx-index-js" */),
  "component---src-pages-control-systems-lutron-index-js": () => import("./../../../src/pages/control-systems/lutron/index.js" /* webpackChunkName: "component---src-pages-control-systems-lutron-index-js" */),
  "component---src-pages-control-systems-pharos-index-js": () => import("./../../../src/pages/control-systems/pharos/index.js" /* webpackChunkName: "component---src-pages-control-systems-pharos-index-js" */),
  "component---src-pages-control-systems-tridonic-index-js": () => import("./../../../src/pages/control-systems/tridonic/index.js" /* webpackChunkName: "component---src-pages-control-systems-tridonic-index-js" */),
  "component---src-pages-cookies-esp-js": () => import("./../../../src/pages/cookiesESP.js" /* webpackChunkName: "component---src-pages-cookies-esp-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kumux-lighting-solution-js": () => import("./../../../src/pages/kumux-lighting-solution.js" /* webpackChunkName: "component---src-pages-kumux-lighting-solution-js" */),
  "component---src-pages-legal-esp-js": () => import("./../../../src/pages/legalESP.js" /* webpackChunkName: "component---src-pages-legal-esp-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-light-and-circadian-rhythm-js": () => import("./../../../src/pages/light-and-circadian-rhythm.js" /* webpackChunkName: "component---src-pages-light-and-circadian-rhythm-js" */),
  "component---src-pages-privacy-esp-js": () => import("./../../../src/pages/privacyESP.js" /* webpackChunkName: "component---src-pages-privacy-esp-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-beable-office-js": () => import("./../../../src/pages/projects/beable_office.js" /* webpackChunkName: "component---src-pages-projects-beable-office-js" */),
  "component---src-pages-projects-fernandez-molina-office-js": () => import("./../../../src/pages/projects/fernandez_molina_office.js" /* webpackChunkName: "component---src-pages-projects-fernandez-molina-office-js" */),
  "component---src-pages-projects-upc-school-js": () => import("./../../../src/pages/projects/upc_school.js" /* webpackChunkName: "component---src-pages-projects-upc-school-js" */),
  "component---src-pages-services-kumux-api-js": () => import("./../../../src/pages/services/kumux-api.js" /* webpackChunkName: "component---src-pages-services-kumux-api-js" */),
  "component---src-pages-services-kumux-platform-integrators-js": () => import("./../../../src/pages/services/kumux-platform-integrators.js" /* webpackChunkName: "component---src-pages-services-kumux-platform-integrators-js" */),
  "component---src-pages-services-kumux-platform-js": () => import("./../../../src/pages/services/kumux-platform.js" /* webpackChunkName: "component---src-pages-services-kumux-platform-js" */),
  "component---src-pages-services-spectrum-design-js": () => import("./../../../src/pages/services/spectrum-design.js" /* webpackChunkName: "component---src-pages-services-spectrum-design-js" */),
  "component---src-pages-simulator-index-js": () => import("./../../../src/pages/simulator/index.js" /* webpackChunkName: "component---src-pages-simulator-index-js" */),
  "component---src-pages-solutions-call-center-index-js": () => import("./../../../src/pages/solutions/call-center/index.js" /* webpackChunkName: "component---src-pages-solutions-call-center-index-js" */),
  "component---src-pages-solutions-elderly-care-index-js": () => import("./../../../src/pages/solutions/elderly-care/index.js" /* webpackChunkName: "component---src-pages-solutions-elderly-care-index-js" */),
  "component---src-pages-solutions-hospital-index-js": () => import("./../../../src/pages/solutions/hospital/index.js" /* webpackChunkName: "component---src-pages-solutions-hospital-index-js" */),
  "component---src-pages-solutions-hotel-index-js": () => import("./../../../src/pages/solutions/hotel/index.js" /* webpackChunkName: "component---src-pages-solutions-hotel-index-js" */),
  "component---src-pages-solutions-office-index-js": () => import("./../../../src/pages/solutions/office/index.js" /* webpackChunkName: "component---src-pages-solutions-office-index-js" */),
  "component---src-pages-solutions-school-index-js": () => import("./../../../src/pages/solutions/school/index.js" /* webpackChunkName: "component---src-pages-solutions-school-index-js" */)
}

